import axios from 'axios';
const BASEURL = process.env.REACT_APP_BASE_URL;

export const login = async (data, props) => {
    const result = await axios.post(`${BASEURL}/user/login`, data);
    if (result.data.success) {
        localStorage.setItem('tokenRekap', result.data.token);
        props.history.push('/dashboard');
        return result.data.userData;
    } else {
        alert('login gagal, username dan password tidak ditemukan');
        return undefined;
    }
};

export const getContentByGrup = async (grup) => {
    const result = await axios.get(`${BASEURL}/content/${grup}`);
    if (result.data.success) {
        return result.data.data;
    } else {
        alert('gagal mendapatkan data');
        return;
    }
};

export const getContentAndGrup = async () => {
    const result = await axios.get(`${BASEURL}/content/grup`);
    if (result.data.success) {
        return result.data.data;
    } else {
        alert('gagal mendapatkan data');
        return;
    }
};

export const updateContent = async (data) => {
    const result = await axios.put(`${BASEURL}/content/`, data);
    if (result.data.success) {
        alert('success update data');
        return result.data.data;
    } else {
        alert('gagal mengupdate data');
        return;
    }
};
