import React, { Component } from 'react';
import { HashRouter as Router, Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import DashboardAdmin from '../components/View/Admin/DashboardAdmin';
import Login from '../components/View/Login';
import DashboardUser from '../components/View/User/DashboardUser';
import TempAcreditation from '../components/View/User/TempAcreditation';
// import Register from '../views/Register';
// import DashboardUser from '../components/View/User/DashboardUser';
import { extractToken } from '../utils/helper';

class router extends Component {
    toDashboard = (props) => {
        // route by role/jabatan
        const extractedToken = extractToken();
        // console.log('ex', extractedToken);
        if (extractedToken.role === 'admin') {
            return <DashboardAdmin data={extractedToken} {...props} />;
        } else {
            return <DashboardUser data={extractedToken} {...props} />;
        }
        //
    };

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={Login} />
                    <Route exact path='/main' component={TempAcreditation} />
                    {/* <Route path='/tes' component={DashboardAdmin} /> */}
                    <Route path='/dashboard' component={(props) => this.toDashboard(props)} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default router;
