import React, { Component } from 'react';
import { getContentAndGrup } from '../../../services/api';
import CreateModal from './CreateModal';
import EditModal from './EditModal';

class DashboardAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const data = await getContentAndGrup();
        this.setState({ data: data });
    };

    render() {
        const { data } = this.state;
        return (
            <div>
                <section class='page-header page-header-classic'>
                    <div class='container'>
                        <div class='row d-flex justify-content-between'>
                            <div>
                                <h1 data-title-border>Welcome Admin</h1>
                            </div>
                            <div>
                                <a
                                    className='primary'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        localStorage.removeItem('tokenRekap');
                                        return this.props.history.push('/');
                                    }}
                                >
                                    Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <section className='card card-admin'>
                                <header className='card-header'>
                                    <h2 className='card-title'>Manajemen Konten User</h2>
                                </header>
                                {/* <CreateModal dataContent={data} {...this.props} /> */}
                                <div class='card-body'>
                                    {/* <div class='row'>
                                        <div class='col-sm-12'>
                                            <div class='mb-3'>
                                                <button
                                                    id='addToTable'
                                                    class='btn btn-primary'
                                                    data-toggle='modal'
                                                    data-target='#exampleModal'
                                                >
                                                    Add <i class='fas fa-plus'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                    <table
                                        class='table table-responsive-lg table-bordered table-striped table-sm mb-0'
                                        id='datatable-editable'
                                    >
                                        <thead>
                                            <tr>
                                                <th>Grup</th>
                                                <th>URL Data Studio</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item) => {
                                                return (
                                                    <>
                                                        <tr key={item._id}>
                                                            <td>{item.grup}</td>
                                                            <td>{item.url}</td>
                                                            <td class='actions'>
                                                                <button
                                                                    class='btn btn-primary on-default edit-row'
                                                                    data-toggle='modal'
                                                                    data-target={`#editModal${item._id}`}
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button class='btn btn-danger on-default remove-row'>
                                                                    Hapus
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <EditModal
                                                            dataContent={item}
                                                            fetchData={this.fetchData}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <footer className='card-footer'></footer>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardAdmin;
