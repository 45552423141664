import React, { Component } from 'react';
import { updateContent } from '../../../services/api';

class EditModal extends Component {
    constructor(props) {
        super(props);
        this.state = { grup: '', url: '' };
    }

    render() {
        return (
            <div
                class={`modal fade`}
                id={`editModal${this.props.dataContent._id}`}
                tabindex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
            >
                <div class='modal-dialog' role='document'>
                    <div class='modal-content'>
                        <div class='modal-header'>
                            <h5 class='modal-title' id='exampleModalLabel'>
                                Edit Konten
                            </h5>
                            <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div class='modal-body'>
                            <div className='form-horizontal form-bordered'>
                                <div class='form-group row'>
                                    <label
                                        class='col-lg-3 control-label text-lg-right pt-2'
                                        for='inputDefault'
                                    >
                                        Grup
                                    </label>
                                    <div class='col-lg-6'>
                                        <select
                                            class='form-control mb-3'
                                            // onChange={(e) => this.setState({ grup: e.target.value })}
                                        >
                                            <option value={this.props.dataContent.grup}>
                                                {this.props.dataContent.grup}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label
                                        class='col-lg-3 control-label text-lg-right pt-2'
                                        for='inputDefault'
                                    >
                                        URL Data Studio
                                    </label>
                                    <div class='col-lg-6'>
                                        <input
                                            type='text'
                                            class='form-control'
                                            id='inputDefault'
                                            value={this.state.url}
                                            onChange={(e) => this.setState({ url: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='modal-footer'>
                            <button type='button' class='btn btn-secondary' data-dismiss='modal'>
                                Close
                            </button>
                            <button
                                type='button'
                                class='btn btn-primary'
                                onClick={async () => {
                                    await updateContent({
                                        grup: this.props.dataContent.grup,
                                        url: this.state.url,
                                    });
                                    return this.props.fetchData();
                                }}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditModal;
