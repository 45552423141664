import React, { Component } from 'react';
import { getContentByGrup } from '../../../services/api';

class DashboardUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {},
        };
    }

    // fetch content url
    componentDidMount() {
        this.fetchContent();
    }

    fetchContent = async () => {
        const content = await getContentByGrup(this.props.data.grup);
        this.setState({ content: content });
    };

    render() {
        const { content } = this.state;
        return (
            <div role='main'>
                <div className='container-fluid'>
                    <iframe
                        src={content ? content.url : ''}
                        frameBorder='0'
                        style={{ border: 0, width: '100%', height: '100vh', overflow: 'hidden' }}
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        );
    }
}

export default DashboardUser;
