import jwt_decode from 'jwt-decode';

/**
 * @DESC extract token value
 */
export const extractToken = () => {
    const token = localStorage.getItem('tokenRekap');

    if (token) {
        const decoded = jwt_decode(token);
        return decoded;
    } else {
        return;
    }
};
