import './App.css';
// import Login from './components/View/Login';
import Router from './routers';

function App() {
    return (
        <div className='App'>
            <Router />
        </div>
    );
}

export default App;
