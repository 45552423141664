import React, { Component } from 'react';
import { login } from '../../services/api';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    handleUsernameChange = (e) => {
        return this.setState({ username: e.target.value });
    };

    handlePasswordChange = (e) => {
        return this.setState({ password: e.target.value });
    };

    handleBtnLogin = async () => {
        const loginData = await login(this.state, this.props);
        if (loginData) {
            // console.log('dataa', loginData);
        }
    };

    render() {
        return (
            <div role='main' class='main'>
                <section class='page-header page-header-classic'>
                    <div class='container'>
                        <div class='row'>
                            <div class='col p-static'>
                                <h1 data-title-border>Login</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div class='container'>
                    <div class='row'>
                        <div class='col'>
                            <div class='featured-boxes'>
                                <div class='row'>
                                    <div class='col-md-6 mx-auto text-center'>
                                        <div class='featured-box featured-box-primary text-left mt-5'>
                                            <div class='box-content'>
                                                <h4 class='color-primary font-weight-semibold text-4 text-uppercase mb-3'>
                                                    Welcome
                                                </h4>
                                                <div
                                                    action='/'
                                                    id='frmSignIn'
                                                    method='post'
                                                    class='needs-validation'
                                                >
                                                    <div class='form-row'>
                                                        <div class='form-group col'>
                                                            <label class='font-weight-bold text-dark text-2'>
                                                                Username
                                                            </label>
                                                            <input
                                                                type='text'
                                                                value={this.state.username}
                                                                onChange={this.handleUsernameChange}
                                                                class='form-control form-control-lg'
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class='form-row'>
                                                        <div class='form-group col'>
                                                            <label class='font-weight-bold text-dark text-2'>
                                                                Password
                                                            </label>
                                                            <input
                                                                type='password'
                                                                value={this.state.password}
                                                                onChange={this.handlePasswordChange}
                                                                class='form-control form-control-lg'
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class='form-row'>
                                                        <div class='form-group col-lg-6'></div>
                                                        <div class='form-group col-lg-6'>
                                                            <input
                                                                type='button'
                                                                value='Login'
                                                                class='btn btn-primary btn-modern float-right'
                                                                data-loading-text='Loading...'
                                                                onClick={(props) =>
                                                                    this.handleBtnLogin(props)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
